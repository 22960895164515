'use client';
import type { NextPage } from 'next';
import type { FollowsType } from '~/components/maps/FollowsSelection';
import type { MapCluster, MapGroup, MapNode } from '~/types/graphika-types';
import type {
  SegmentSelection,
  SegmentTreeDefaults,
} from '~/lib/stores/segment-tree';
import {
  DownloadParams,
  MapViewMode,
  MapViewerLabel,
  MvConfigProvider,
  PointCloudProvider,
  useCreateMvConfig,
  useStateEffect,
} from '@graphika/map-viewer';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { BodyText, Box, Flex } from '~/components';
import {
  MapSidebar,
  MapViewSelection,
  MapViewerContainer,
  NodeSearchModal,
  SegmentTreeListener,
} from '~/components/maps/';
import { FollowsSelection } from '~/components/maps/FollowsSelection';
import {
  SegmentTreeProvider,
  useCreateSegmentTree,
} from '~/lib/stores/segment-tree';
import { useAnalytics } from '~/lib/analytics';
import { useMap, useMapClusters, useMapGroups, useMapNodes } from '~/queries';
import { colors } from '~/styles';

const Map: NextPage = () => {
  const router = useRouter();
  const map_id = parseInt(router.query.mapId as string);
  const { data: mapData } = useMap({ map_id });
  const { data: nodes = [], isLoading: isLoadingNodes } = useMapNodes({
    map_id,
  });
  const { data: groups = [] } = useMapGroups({ map_id });
  const { data: clusters = [] } = useMapClusters({ map_id });
  const [viewMode, setViewMode] = useState<MapViewMode>('3d');
  const [showNodeSearchModal, setShowNodeSearchModal] = useState(false);
  const [highlightedNode, setHighlightedNode] = useState<MapNode>();
  const [download, setDownload] = useState<DownloadParams>();
  const [customizedGroups, setCustomizedGroups] = useState<MapGroup[]>([]);
  const [customizedClusters, setCustomizedClusters] = useState<MapCluster[]>(
    []
  );
  const [followType, setFollowType] = useState<FollowsType>('follower');

  const useMvConfig = useCreateMvConfig();
  const useSegmentTree = useCreateSegmentTree();

  const [labels, onSegmentChange] = useMapLabels({
    clusters: customizedClusters,
    groups: customizedGroups,
  });

  useAnalytics('screenView', { name: `Map: ${mapData?.name}` }, [mapData]);
  const isShowingLabels = labels.some((label) => label.visible);

  const sortByPosition = (a: MapGroup, b: MapGroup) =>
    a.position > b.position ? 1 : -1;

  useEffect(() => {
    const getStorageGroups = (): MapGroup[] => {
      return JSON.parse(
        sessionStorage.getItem(`${mapData?.id ?? ''}-custom-colors`) ?? '[]'
      ).sort(sortByPosition);
    };
    const updateSegments = () => {
      if (!groups.length || !clusters.length) return;
      const resolvedGroups = getStorageGroups().length
        ? getStorageGroups()
        : groups;
      setCustomizedGroups(resolvedGroups);
      const newClusters = clusters.map((cluster) => {
        return {
          ...cluster,
          hex_color:
            resolvedGroups.find((group) => cluster.group_id === group.id)
              ?.hex_color ?? cluster.hex_color,
        };
      });
      setCustomizedClusters(newClusters);
    };

    if (getStorageGroups().length > 0) {
      updateSegments();
    } else if (clusters.length > 0 && groups.length > 0) {
      setCustomizedGroups(groups.sort(sortByPosition));
      setCustomizedClusters(clusters);
    }
    window.addEventListener('customize-group-colors', updateSegments);
    return () => {
      window.removeEventListener('customize-group-colors', updateSegments);
    };
  }, [groups, clusters, mapData?.id]);

  if (!mapData) return null;

  return (
    <Box height="calc(100vh - 30px)" overflow="hidden" bg={colors.mapViewer.bg}>
      <Head>
        <title>{`Graphika - Map: ${mapData.name}`}</title>
        <meta
          name="description"
          content={`SaaS app for Graphika | Map ${
            mapData?.name && `| ${mapData.name}`
          }`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <SegmentTreeProvider value={useSegmentTree}>
        <MvConfigProvider value={useMvConfig}>
          <PointCloudProvider>
            <Flex h="100%">
              <MapSidebar
                map={mapData}
                groups={customizedGroups}
                clusters={customizedClusters}
                onChange={onSegmentChange}
                highlightedNode={highlightedNode}
                setHighlightedNode={setHighlightedNode}
                nodes={nodes}
              />
              {isLoadingNodes ? (
                <Box
                  position="fixed"
                  top="30px"
                  bottom={0}
                  right={0}
                  left={398}
                >
                  <Flex w="100%" h="100%" align="center" justify="center">
                    <Box
                      borderRadius={8}
                      bg="#222A3A"
                      py={2}
                      px={4}
                      position="relative"
                      overflow="hidden"
                    >
                      <BodyText color={colors.white}>
                        Generating map...
                      </BodyText>
                      <Box
                        position="absolute"
                        bottom={0}
                        right={0}
                        left={0}
                        h={1}
                        bg={colors.mapViewer.bgGray}
                      ></Box>
                    </Box>
                  </Flex>
                </Box>
              ) : (
                <MapViewerContainer
                  labels={labels}
                  nodes={nodes}
                  groups={customizedGroups}
                  highlightedNode={highlightedNode}
                  setHighlightedNode={setHighlightedNode}
                  clusters={customizedClusters}
                  viewMode={viewMode}
                  download={download}
                  setDownload={setDownload}
                  map={mapData}
                  followType={followType}
                />
              )}
            </Flex>
            <MapViewSelection
              viewMode={viewMode}
              setViewMode={setViewMode}
              map={mapData!}
              setDownload={setDownload}
              isShowingLabels={isShowingLabels}
              setShowNodeSearchModal={setShowNodeSearchModal}
              groups={customizedGroups}
            />
          </PointCloudProvider>
        </MvConfigProvider>
        {showNodeSearchModal && (
          <NodeSearchModal
            nodes={nodes}
            onClose={() => setShowNodeSearchModal(false)}
            map={mapData}
            highlightedNode={highlightedNode}
          />
        )}
        {highlightedNode && (
          <FollowsSelection
            highlightedNode={highlightedNode}
            nodes={nodes}
            groups={customizedGroups}
            clusters={customizedClusters}
            map={mapData}
            followType={followType}
            setFollowType={setFollowType}
          />
        )}
      </SegmentTreeProvider>
    </Box>
  );
};

export default Map;

export function useMapLabels({
  clusters,
  groups,
  initialSegments,
  defaults,
}: {
  clusters: MapCluster[];
  groups: MapGroup[];
  initialSegments?: SegmentSelection;
  defaults?: SegmentTreeDefaults;
}) {
  const [labels, setLabels] = useStateEffect<MapViewerLabel[]>(() => {
    return clusters
      .map((cluster) => {
        return segmentToLabel(
          cluster,
          initialSegments?.clusters[cluster.id]?.showLabel ??
            !!defaults?.cluster.showLabel
        );
      })
      .concat(
        groups.map((group) =>
          segmentToLabel(
            group,
            initialSegments?.groups[group.id]?.showLabel ??
              !!defaults?.group.showLabel
          )
        )
      );
  }, [clusters, groups]);

  const onChange: SegmentTreeListener = useCallback(
    (groups, clusters) => {
      setLabels((labels: MapViewerLabel[]) =>
        labels.map((label: MapViewerLabel) => {
          const visible =
            'group' in label.selector
              ? groups[label.selector.group]?.showLabel
              : 'cluster' in label.selector
              ? clusters[label.selector.cluster]?.showLabel
              : label.visible;
          return { ...label, visible };
        })
      );
    },
    [setLabels]
  );
  return [labels, onChange] as const;
}

type MapSegment = MapCluster | MapGroup;
function segmentToLabel(
  segment: MapSegment,
  visible?: boolean
): MapViewerLabel {
  return {
    selector:
      'group_id' in segment ? { cluster: segment.id } : { group: segment.id },
    color: `#${segment.hex_color}`,
    text: segment.name,
    visible,
  };
}
