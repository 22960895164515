import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PropsWithChildren, useState } from 'react';
import { BoxProps, Fade, keyframes } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIsFetching } from '@tanstack/react-query';
import { Box, Flex } from '~/components';
import { FaIcon } from '~/componentsV2';
import { useUser } from '~/lib/hooks';
import {
  authoringRouteRegex,
  dashboardRouteRegex,
  feedsRouteRegex,
  isV1Design,
  mapviewerRouteRegex,
} from '~/lib/routing';
import { zIndices, colors } from '~/styles/v2';
import { NavBarAccountMenu } from './NavBarAccountMenu';
import styles from './navbar.module.css';

type Props = {};

const bg = keyframes`
from {
  transform: translateX(0)
} to {
  transform: translateX(32px)
}`;

export function PrimaryNavBar(props: Props) {
  const { userInfo } = useUser();
  const { pathname } = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isFetching = useIsFetching();
  const isNavigationDisabled = userInfo?.password_reset_required;

  return (
    <Box
      h="30px"
      bg={colors.primary[600]}
      px={4}
      pointerEvents={isNavigationDisabled ? 'none' : 'auto'}
    >
      {isV1Design(pathname) && (
        <Box
          position="fixed"
          left="-23px"
          right="23px"
          top="30px"
          zIndex={zIndices.banner}
        >
          <Fade
            in={isFetching !== 0}
            transition={{
              enter: {
                delay: 0.1,
                duration: 0.2,
              },
              exit: {
                delay: 0.1,
                duration: 0.2,
              },
            }}
          >
            <Box
              background={`repeating-linear-gradient(
            145deg,
            ${colors.primary[600]} 1px,
            ${colors.primary[100]} 2px,
            ${colors.primary[100]} 11px,
            ${colors.primary[600]} 12px,
            ${colors.primary[600]} 20px
          );`}
              w={`calc(100vw + 46px)`}
              animation={`${bg} infinite 1s linear`}
              h="2px"
            />
          </Fade>
        </Box>
      )}
      <Flex justify="space-between">
        <Flex align="center">
          {['admin', 'analyst'].includes(userInfo?.role ?? '') && (
            <NavButton
              icon="home"
              active={dashboardRouteRegex.test(pathname)}
              link="/dashboard"
            />
          )}
          <NavButton
            icon="map"
            active={mapviewerRouteRegex.test(pathname)}
            link="/maps"
          />
          {/* <NavButton icon="industry" /> */}
          <NavButton
            icon="file-lines"
            active={feedsRouteRegex.test(pathname)}
            link="/feeds"
          />
        </Flex>
        <Flex align="center">
          {(userInfo?.role === 'analyst' || userInfo?.role === 'admin') && (
            <NavButton
              icon="pen-to-square"
              active={authoringRouteRegex.test(pathname)}
              link="/authoring"
            />
          )}
          {/* <NavButton icon="triangle-exclamation" /> */}
          <NavButton icon="user" onClick={() => setIsMenuOpen(!isMenuOpen)} />
          <NavBarAccountMenu
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        </Flex>
      </Flex>
    </Box>
  );
}

type NavButtonProps = BoxProps & {
  active?: boolean;
  icon: IconProp;
  link?: string;
};

const NavButton = ({ active, icon, link, ...rest }: NavButtonProps) => {
  const Wrapper = ({ children }: PropsWithChildren<{}>) =>
    link ? <Link href={link}>{children}</Link> : <div>{children}</div>;
  return (
    <Wrapper>
      <Box
        as="button"
        w="30px"
        h="30px"
        bg={active ? colors.primary[900] : 'inherit'}
        className={styles.primary_navbar_button}
        {...rest}
      >
        <FaIcon
          icon={icon}
          color={active ? colors.white : colors.primary[100]}
          size="sm"
        />
      </Box>
    </Wrapper>
  );
};
