import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { BoxProps } from '@chakra-ui/react';
import { Center } from '@chakra-ui/react';
import { Flex, FaIcon } from '~/components';
import { Body } from '~/componentsV2';
import { colors } from '~/styles/v2';

type Props = { arr: unknown[]; icon?: IconProp; text?: string } & BoxProps;

export function NoResults<T>({
  arr,
  icon = 'search',
  text = 'No results',
  ...rest
}: Props) {
  if (arr.length > 0) return null;
  return (
    <Center h="150px" {...rest}>
      <Flex direction="column" align="center" gap={2} w="92px">
        <FaIcon icon={icon} color={colors.text} />
        <Body color={colors.secondaryText} align="center">
          {text}
        </Body>
      </Flex>
    </Center>
  );
}
