import type { Mutation, Query } from '@tanstack/react-query';
import type { ApiFailure } from './fetchUtils';
import * as Sentry from '@sentry/nextjs';
import { signOut as googleSignOut } from 'next-auth/react';
import { createVoyagerToast } from '~/components';
import store from '~/redux/store';
import { logoutAction } from '~/redux/userSlice';
import { clearLocalUserState } from '../auth';

// This text is returned by the api when the user needs to reset their password
export const pwResetMsg = 'User must reset password before using this endpoint';
const defaultErrorMessage: Record<number, string> = {
  400: 'Bad request',
  404: 'Resource not found',
};

/**
 * handles various API error scenarios (logout, notifications, logging etc)
 */
export const handleError = (
  error: ApiFailure,
  query?: Query,
  mutation?: Mutation<unknown, unknown, unknown, unknown>
) => {
  if (error.data === pwResetMsg) return;
  const { toast } = createVoyagerToast();

  switch (error.status) {
    case 400:
      if (
        mutation?.options.mutationKey?.toString() ===
        ['narrative_signals', 'classification', 'file'].toString()
      )
        return;
      if (!toast.isActive('400')) {
        toast({
          title: error.data ?? '400 server error',
          status: 'warning',
          id: '400',
        });
      }
      break;
    case 401:
      const state = store.getState();
      if (!state.user.hasCheckedAuthStatus) {
        return;
      }
      if (!toast.isActive('logout')) {
        toast({
          title: 'Logged out due to inactivity',
          status: 'warning',
          id: 'logout',
        });
      }
      if (state.user.userInfo) {
        clearLocalUserState();
        googleSignOut({ redirect: false });
        store.dispatch(logoutAction());
      }
      return;
    case 403:
      if (!toast.isActive('403')) {
        return toast({
          title: "You don't have the necessary permissions",
          description: 'Please contact support',
          id: '403',
        });
      }
    case 500:
      if (!toast.isActive('500')) {
        return toast({
          title: 'Technical Error',
          description:
            'We’ve detected a technical issue. You may experience some minor issues. We’re working to resolve it promptly.',
          id: '500',
        });
      }

    default:
      const queryKey = query?.queryKey.join(',') ?? '';
      Sentry.captureException(
        new Error(`API ERROR: ${error.statusText} ${queryKey}`),
        {
          tags: {
            'api-error': queryKey,
          },
        }
      );
      if (
        ['twitter', 'tweets'].every((value) => query?.queryKey.includes(value)) // do not show error toast for /twitter/tweets due do high failrate of Twitter API
      ) {
        return;
      }
      if (!toast.isActive(error.status)) {
        let title = defaultErrorMessage[error.status] ?? 'Unspecified error';

        if (error.data && typeof error.data === 'string') title = error.data;
        else if (error.statusText) title = error.statusText;

        return toast({
          title: title.replace('<h1>', '').replace('</h1>', ''),
          id: error.status,
        });
      }
  }
};

/**
 * handles react-query retry behavior for different errors
 */
export const handleRetry = (
  failureCount: number,
  error: ApiFailure
): boolean => {
  if ([401, 404, 500].includes(error.status) || error.data === pwResetMsg) {
    return false;
  }
  if (failureCount === 3) {
    return false;
  }
  return true;
};
