import type { MapInfo, NarrativeFeed } from '~/types/graphika-types';
import { useEffect, useMemo, useState } from 'react';
import {
  LightMode,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SystemStyleObject,
} from '@chakra-ui/react';
import { BodyText, Box, Flex, Icon, Input } from '~/components';
import { Analytics } from '~/lib/analytics';
import { useDebounceCallback } from '~/lib/hooks';
import { colors } from '~/styles';
import FileLinesIcon from '~/public/icons/FileLinesDark.svg';
import NavigationDownIcon from '~/public/icons/Navigation/Down.svg';
import PeopleIcon from '~/public/icons/Peoples.svg';
import SearchIcon from '~/public/icons/Search.svg';
import TwitterIcon from '~/public/mv-icons/mv-twitter.svg';
import FacebookIcon from '~/public/mv-icons/mv-facebook.svg';
import TelegramIcon from '~/public/mv-icons/mv-telegram.svg';
import VkIcon from '~/public/mv-icons/mv-vk.svg';
import YoutubeIcon from '~/public/mv-icons/mv-youtube.svg';
import { useNarrativeFeeds } from '~/queries';
import { scrollbarStyleLight } from '~/styles/components';

const menuButtonStyle: SystemStyleObject = {
  bg: colors.warmGray[6],
  border: `1px solid ${colors.warmGray[4]}`,
  p: 2,
  borderRadius: 8,
  h: '40px',
  _focus: {
    outline: 'none',
  },
};

const menuListStyle: SystemStyleObject = {
  py: 2,
  zIndex: 2,
  maxH: '80vh',
  overflowY: 'auto',
  ...scrollbarStyleLight,
};

export const platformOptions = [
  { name: 'All Platforms', icon: () => null, type: undefined },
  { name: 'X', icon: TwitterIcon, type: 'twitter' },
  { name: 'Facebook', icon: FacebookIcon, type: 'facebook' },
  { name: 'YouTube', icon: YoutubeIcon, type: 'youtube' },
  { name: 'VK', icon: VkIcon, type: 'vkontakte' },
  { name: 'Telegram', icon: TelegramIcon, type: 'telegram' },
];

type Props = {
  maps: MapInfo[];
  setFilteredMaps: (maps: MapInfo[]) => void;
};

export function MapFilter({ maps, setFilteredMaps }: Props) {
  const { data: feeds = [] } = useNarrativeFeeds();
  const orderedFeeds = useMemo(
    () => [
      { name: 'All Feeds', type: undefined, id: 0 },
      ...feeds
        .filter((feed) => feed.type === 'general')
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
      ...feeds
        .filter((feed) => feed.type === 'custom')
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    ],
    [feeds]
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFeed, setSelectedFeed] = useState<Partial<NarrativeFeed>>(
    orderedFeeds[0]
  );
  const [selectedPlatform, setSelectedPlatform] = useState(platformOptions[0]);

  useEffect(() => {
    const filteredBySearchTerm = maps.filter(
      (map) =>
        map.name
          ?.toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        map.id?.includes(searchTerm)
    );
    const filteredByPlatform = filteredBySearchTerm.filter((map) =>
      selectedPlatform?.name !== 'All Platforms'
        ? map.type === selectedPlatform.type
        : true
    );
    const filteredByFeed = filteredByPlatform.filter((map) =>
      selectedFeed.name !== 'All Feeds'
        ? map.narrative_feed_ids?.includes(selectedFeed.id!)
        : true
    );
    setFilteredMaps(filteredByFeed);
  }, [maps, searchTerm, selectedFeed, selectedPlatform, setFilteredMaps]);

  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
  };
  const handlePlatformChange = (platform: (typeof platformOptions)[number]) => {
    Analytics.event('maps', {
      action: 'maps_platform_filter',
    });
    setSelectedPlatform(platform);
  };
  const handleFeedChange = (feed: Partial<NarrativeFeed>) => {
    Analytics.event('maps', {
      action: 'maps_feed_filter',
    });
    setSelectedFeed(feed);
  };

  useDebounceCallback(
    () =>
      Analytics.event('search', {
        action: 'maps',
        label: searchTerm,
      }),
    searchTerm,
    4000
  );
  return (
    <Box>
      <LightMode>
        <Flex align="center" gap={2}>
          <Box
            mt={4}
            ml={4}
            bg={colors.warmGray[4]}
            borderRadius={8}
            border={`1px solid ${colors.warmGray[3]}`}
            w="366px"
          >
            <Flex>
              <Box bg={colors.warmGray[6]} borderLeftRadius={8} pt={2} pl={2}>
                <Icon icon={SearchIcon} boxSize={6} fill={colors.g.primary} />
              </Box>
              <Input
                placeholder="Search by map name or ID"
                border="none !important"
                borderRightRadius={8}
                borderLeftRadius={0}
                size="lg"
                w={366}
                pl={2}
                value={searchTerm}
                onChange={({ currentTarget: { value } }) => {
                  handleSearchTermChange(value);
                }}
                bg={colors.warmGray[6]}
                data-cy="map-search-input"
                color={colors.black}
              />
            </Flex>
          </Box>
          <Flex gap={2} mt={4}>
            <Menu>
              <MenuButton sx={{ ...menuButtonStyle }}>
                <Flex justify="space-between" align="center">
                  <Flex align="center">
                    <Icon icon={PeopleIcon} boxSize={4} mt={0.5} mr={2} />

                    <BodyText>
                      {selectedPlatform?.name ?? 'All Platforms'}
                    </BodyText>
                  </Flex>
                  <Icon
                    icon={NavigationDownIcon}
                    boxSize={5}
                    ml={1}
                    mr={-2}
                    fill={colors.black}
                  />
                </Flex>
              </MenuButton>
              <MenuList sx={menuListStyle}>
                {platformOptions.map((platform) => (
                  <MenuItem
                    key={platform.name}
                    onClick={() => handlePlatformChange(platform)}
                  >
                    <Flex w="100%" align="center" gap={2}>
                      {platform.type && (
                        <Icon
                          icon={platform.icon}
                          boxSize={6}
                          fill={colors.black}
                        />
                      )}
                      <BodyText>{platform.name}</BodyText>
                    </Flex>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton sx={{ ...menuButtonStyle }}>
                <Flex justify="space-between" align="center">
                  <Flex align="center" gap={2}>
                    <Box h="24px" w="20px">
                      <Icon icon={FileLinesIcon} boxSize={6} />
                    </Box>

                    <BodyText whiteSpace="nowrap">
                      {selectedFeed ? selectedFeed.name : 'All Feeds'}{' '}
                    </BodyText>
                  </Flex>
                  <Icon
                    icon={NavigationDownIcon}
                    boxSize={5}
                    ml={1}
                    mr={-2}
                    fill={colors.black}
                  />
                </Flex>
              </MenuButton>
              <MenuList sx={menuListStyle}>
                {orderedFeeds.map((feed) => (
                  <MenuItem
                    key={feed.id ?? 'all feeds'}
                    onClick={() => handleFeedChange(feed)}
                  >
                    <Box w="100%">
                      {feed.type && (
                        <BodyText fontSize="12px" color={colors.warmGray[1]}>
                          {feed.type === 'custom' ? 'Telescope' : 'Atlas'}
                        </BodyText>
                      )}
                      <BodyText whiteSpace="nowrap">{feed.name}</BodyText>
                    </Box>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </LightMode>
    </Box>
  );
}
