import type { PropsWithChildren } from 'react';
import { Box, BoxProps, Flex, TextProps } from '@chakra-ui/react';
import { Body } from './TypographyV2';
import { colorsV2 as colors } from '~/styles/v2/colorsV2';

type LabelVariation = 'mapLabel' | 'feed' | 'label' | 'classification';
type LabelSize = 'normal' | 'compact';

const variationStyling: Record<LabelVariation, BoxProps> = {
  classification: {
    bg: colors.error,
    color: colors.white,
  },
  feed: { background: colors.surface[200], px: 2 },
  label: { background: colors.neutral[50] },
  mapLabel: {
    background: colors.neutral[0],
    border: `1px solid ${colors.neutral[100]}`,
  },
};

const variationTextStyling: Partial<Record<LabelVariation, TextProps>> = {
  classification: {
    color: colors.white,
  },
  mapLabel: {
    fontSize: '14px',
    py: 1,
  },
};

const sizeStyling: Record<LabelSize, BoxProps> = {
  normal: { borderRadius: 8, px: 1.5, py: 0.5 },
  compact: { borderRadius: 4, px: 1.5, py: '1px' },
};

type LabelProps = BoxProps &
  PropsWithChildren<{
    dotColor?: string;
    size?: LabelSize;
    textProps?: TextProps;
    variation?: LabelVariation;
  }>;

export function Label({
  children,
  dotColor,
  size = 'normal',
  textProps,
  variation = 'label',
  ...props
}: LabelProps) {
  const sizeStyle = sizeStyling[size as LabelSize];
  const variationStyle = variationStyling[variation as LabelVariation];
  const fontSize = size === 'normal' ? 'normal' : 'small';
  const textStyle = variationTextStyling[variation] ?? { color: colors.text };
  return (
    <Box maxWidth="fit-content" {...sizeStyle} {...variationStyle} {...props}>
      <Flex gap={size === 'normal' ? 2 : 1} align="center">
        {!!dotColor && (
          <Box
            w={2}
            h={2}
            minH={2}
            minW={2}
            borderRadius="100%"
            bg={dotColor}
          />
        )}
        {/* @ts-ignore */}
        <Body fontSize={fontSize} {...textStyle} {...textProps}>
          {children}
        </Body>
      </Flex>
    </Box>
  );
}
