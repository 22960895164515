import { PropsWithChildren, useRef } from 'react';
import { useRouter } from 'next/router';
import { signOut as googleSignOut } from 'next-auth/react';
import { BoxProps, useColorMode, useOutsideClick } from '@chakra-ui/react';
import { Box, FaIcon, Flex, Icon, Switch } from '~/components';
import { Body, Link } from '~/componentsV2';
import { logoutAction } from '~/redux/userSlice';
import { useAppDispatch } from '~/redux/store';
import { clearLocalUserState, logout as apiLogout } from '~/lib/auth';
import { useUser } from '~/lib/hooks';
import { colors, zIndices } from '~/styles/v2';
import DarkMode from '~/public/icons/Dark Mode.svg';
import LightMode from '~/public/icons/Light Mode.svg';
import { dashboardRouteRegex } from '~/lib/routing';

type Props = {
  isMenuOpen: boolean;
  setIsMenuOpen: (open: boolean) => void;
};

export function NavBarAccountMenu({ isMenuOpen, setIsMenuOpen }: Props) {
  const { userInfo } = useUser();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { toggleColorMode, colorMode } = useColorMode();
  const ref = useRef<HTMLDivElement>(null);
  const showColorModeToggle = !dashboardRouteRegex.test(router.pathname);

  useOutsideClick({
    ref,
    handler: () => setIsMenuOpen(false),
  });
  if (!userInfo) return null;

  const { email, name, role } = userInfo;

  const onLogout = async () => {
    try {
      await apiLogout();
      await googleSignOut({ redirect: false });
    } catch (e) {
      console.error('error logging out', e);
      dispatch(logoutAction());
      router.push('/login');
    } finally {
      clearLocalUserState();
      dispatch(logoutAction());
      router.push('/login');
    }
  };

  if (!isMenuOpen) {
    return null;
  }
  return (
    <Box
      position="fixed"
      top={9}
      right={2}
      zIndex={zIndices.popover}
      border={`1px solid ${colors.outline}`}
      borderRadius={8}
      bg={colors.white}
      pt={4}
      w="242px"
      ref={ref}
    >
      <Flex direction="column">
        <Box mb={4} px={4}>
          <Body>{name}</Body>
          <Body color={colors.secondaryText}>{email}</Body>
        </Box>
        {showColorModeToggle && (
          <Flex align="center" justify="space-between" w="100%" gap={2} px={4}>
            <Body>Appearance</Body>
            <Flex gap={2}>
              <Icon
                icon={LightMode}
                color={
                  colorMode === 'light'
                    ? colors.primary[500]
                    : colors.secondaryText
                }
                boxSize={6}
                cursor="pointer"
                onClick={toggleColorMode}
              />
              <Switch
                variant="monochrome"
                onChange={toggleColorMode}
                isChecked={colorMode === 'dark'}
              />
              <Icon
                icon={DarkMode}
                color={
                  colorMode === 'dark'
                    ? colors.primary[500]
                    : colors.secondaryText
                }
                boxSize={6}
                cursor="pointer"
                onClick={toggleColorMode}
              />
            </Flex>
          </Flex>
        )}
        {(role === 'admin' || role === 'analyst') && (
          <Section href="/reports">
            <FaIcon icon="inbox" color={colors.secondaryText} size="sm" />
            <Body>My Data & Custom Reports</Body>
          </Section>
        )}
        <Section href={role === 'admin' ? '/admin' : '/settings'}>
          <FaIcon icon="cog" color={colors.secondaryText} size="sm" />
          {role === 'admin' && <Body>Admin Settings</Body>}
          {role !== 'admin' && <Body>User Settings</Body>}
        </Section>
        <Section href="/docs" newTab>
          <FaIcon
            icon="circle-question"
            color={colors.secondaryText}
            size="sm"
          />
          <Body>Help Documents</Body>
        </Section>
        <Section onClick={onLogout}>
          <FaIcon icon="power-off" color={colors.secondaryText} size="sm" />
          <Body>Sign out</Body>
        </Section>
        <Section
          href="https://graphika.com/privacy-policy"
          borderBottom="none"
          bg={colors.neutral[10]}
          display="flex"
          justifyContent="center"
          newTab
        >
          <Body
            size="small"
            color={colors.secondaryText}
            _hover={{
              textDecoration: 'none !important',
            }}
            textAlign="center"
            w="100%"
          >
            Privacy Policy
          </Body>
        </Section>
      </Flex>
    </Box>
  );
}

const Section = ({
  children,
  href,
  newTab = false,
  ...rest
}: PropsWithChildren<BoxProps & { href?: string; newTab?: boolean }>) => {
  const Wrapper = href ? Link : Box;
  return (
    <Box
      borderBottom={`1px solid ${colors.outline}`}
      p={4}
      cursor="pointer"
      _hover={{
        bg: colors.neutral[10],
      }}
      w="100%"
      {...rest}
    >
      <Wrapper href={href ?? ''} newTab={newTab} noTextDecoration w="100%">
        <Flex align="center" gap={2} w="100%">
          {children}
        </Flex>
      </Wrapper>
    </Box>
  );
};
