import type { ReactElement, ReactNode } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { ErrorBoundary, Flex, GlobalErrorFallback } from '~/components';
import { docsRouteRegex, loginRouteRegex } from '~/lib/routing';
import { colors } from '~/styles';
import { useUser } from '~/lib/hooks/useUser';
import { PrimaryNavBar } from '~/features/monitoring-dashboard/components/navbar/PrimaryNavBar';

const ignoreLayoutPaths = [
  loginRouteRegex,
  docsRouteRegex,
  /^\/widget/,
  /^\/design/,
];

export function Layout(props: { children: ReactNode }): ReactElement {
  const { pathname } = useRouter();
  const colorMode = localStorage.getItem('chakra-ui-color-mode');
  const user = useUser();
  const bg = useColorModeValue(
    colors.background.lightMode,
    colors.background.darkMode
  );

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('color-scheme', colorMode || 'light');
  }, [colorMode]);

  if (!user.hasCheckedAuthStatus) return <>{props.children}</>;

  const ignoreLayout = ignoreLayoutPaths.some((r) => r.test(pathname));

  return (
    <ErrorBoundary fallback={GlobalErrorFallback}>
      {ignoreLayout ? (
        <>{props.children}</>
      ) : (
        <Flex bg={bg} direction="column" minH="100vh">
          <PrimaryNavBar />
          <Flex overflow="auto" flex={1} direction="column">
            {props.children}
          </Flex>
        </Flex>
      )}
    </ErrorBoundary>
  );
}
