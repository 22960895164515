import type { MapNode, Platform, Signal } from '~/types/graphika-types';
import type { RedditAccount, SignalPlatformName } from '~/types/signal-types';
import { type ReactNode } from 'react';
import FourChan from '~/public/platform-icons/4chan.svg';
import BitChute from '~/public/platform-icons/BitChute.svg';
import Douban from '~/public/platform-icons/Douban.svg';
import Email from '~/public/platform-icons/Email.svg';
import Facebook from '~/public/platform-icons/Facebook.svg';
import Flickr from '~/public/platform-icons/Flickr.svg';
import Gab from '~/public/platform-icons/Gab.svg';
import Gettr from '~/public/platform-icons/Gettr.svg';
import Google from '~/public/platform-icons/Google.svg';
import Instagram from '~/public/platform-icons/Instagram.svg';
import Parler from '~/public/platform-icons/Parler.svg';
import Pinterest from '~/public/platform-icons/Pinterest.svg';
import Reddit from '~/public/platform-icons/Reddit.svg';
import Rumble from '~/public/platform-icons/Rumble.svg';
import Steam from '~/public/platform-icons/Steam.svg';
import Steemit from '~/public/platform-icons/Steemit.svg';
import Substack from '~/public/platform-icons/Substack.svg';
import Telegram from '~/public/platform-icons/Telegram.svg';
import TikTok from '~/public/platform-icons/TikTok.svg';
import Tumblr from '~/public/platform-icons/Tumblr.svg';
import TruthSocial from '~/public/platform-icons/TruthSocial.svg';
import Twitter from '~/public/platform-icons/Twitter.svg';
import VK from '~/public/platform-icons/VK.svg';
import WeChat from '~/public/platform-icons/WeChat.svg';
import Weibo from '~/public/platform-icons/Weibo.svg';
import WhatsApp from '~/public/platform-icons/WhatsApp.svg';
import YouTube from '~/public/platform-icons/YouTube.svg';
import { hashtagRegex, userRegex } from './signals';
import { urlRegex } from './urls';

const noIcon = null;

export function platformIcon(
  platform: Platform | string
): (() => ReactNode) | null {
  return (
    {
      twitter: Twitter,
      facebook: Facebook,
      youtube: YouTube,
      vkontakte: VK,
      telegram: Telegram,
    }[platform] ?? null
  );
}

export const signalPlatformToIcon = (signal: Signal) => {
  switch (signal.signal_platform) {
    case '4chan':
      return FourChan;
    case 'BitChute':
      return BitChute;
    case 'Douban':
      return Douban;
    case 'Email provider':
      return Email;
    case 'Facebook':
      return Facebook;
    case 'Flickr':
      return Flickr;
    case 'Gab':
      return Gab;
    case 'Gettr':
      return Gettr;
    case 'Google':
      return Google;
    case 'Instagram':
      return Instagram;
    case 'N/A':
      return noIcon;
    case 'Gab':
      return Gab;
    case 'Odysee':
      return 'Odysee';
    case 'Other':
      return noIcon;
    case 'Parler':
      return Parler;
    case 'Pinterest':
      return Pinterest;
    case 'Reddit':
      return Reddit;
    case 'Rumble':
      return Rumble;
    case 'Steam':
      return Steam;
    case 'Steemit':
      return Steemit;
    case 'Substack':
      return Substack;
    case 'Telegram':
      return Telegram;
    case 'TikTok':
      return TikTok;
    case 'Tumblr':
      return Tumblr;
    case 'Twitter':
      return Twitter;
    case 'TruthSocial':
      return TruthSocial;
    case 'VK':
      return VK;
    case 'WeChat':
      return WeChat;
    case 'Weibo':
      return Weibo;
    case 'WhatsApp':
      return WhatsApp;
    case 'YouTube':
      return YouTube;
    default:
      return noIcon;
  }
};

/**
 * REDDIT
 */
export const redditUserScreenName = (signal: Signal<RedditAccount>) =>
  `u/${signal.full_target.entity?.user_name}`;

/**
 * TWITTER
 */
export const twitterHashtagLink = (hashtag: string) =>
  `https://x.com/hashtag/${hashtag
    .trim()
    .slice(hashtag.indexOf('#') === 0 ? 1 : 0)}`;

export const twitterUserLink = (user: string) =>
  `https://x.com/${user.trim().slice(user.indexOf('@') === 0 ? 1 : 0)}`;

export const getTweetLinkFromId = (signal: Signal<unknown>) =>
  signal.target_type === 'post' && Number(signal.signal_value)
    ? `https://x.com/anyuser/status/${signal.signal_value}`
    : undefined; // legacy signals still only have tweet id as signal_value, not the full URL

export const tweetTextToMarkdown = (text: string) => {
  if (!text) return '';
  let md = text;
  md.match(urlRegex)?.forEach((url) => {
    md = md.replaceAll(url, `[${url}](${url})`);
  });
  md.match(hashtagRegex)?.forEach((hashtag) => {
    md = md.replaceAll(hashtag, `[${hashtag}](${twitterHashtagLink(hashtag)})`);
  });
  md.match(userRegex)?.forEach((user) => {
    md = md.replaceAll(user, `[${user}](${twitterUserLink(user)})`);
  });
  return md;
};

export const hashtagLink = (signal: Signal) => {
  const { signal_platform: platform, signal_value } = signal;
  if (!platform) return undefined;
  const hashtagText =
    signal_value.indexOf('#') === 0 ? signal_value.slice(1) : signal_value;

  const links: Partial<Record<SignalPlatformName, string>> = {
    Twitter: `https://www.x.com/hashtag/`,
    Instagram: `https://www.instagram.com/explore/tags/`,
    TikTok: `https://www.tiktok.com/tag/`,
    TruthSocial: `https://www.truthsocial.com/tags/`,
    Facebook: `https://www.facebook.com/hashtag/`,
  };

  return links[platform]
    ? `${links[platform]}${encodeURIComponent(hashtagText)}`
    : undefined;
};

export const platformName = (platform: SignalPlatformName | undefined) =>
  platform === 'Twitter' ? 'X' : platform;

export const getNodeAccountLink = (node: MapNode | undefined) => {
  if (!node) return '';
  switch (node.platform) {
    case 'twitter':
      return `https://x.com/${node.username}`;
    case 'youtube':
      return `https://youtube.com/channel/${node.node_source_id}`;
    case 'telegram':
      return `https://t.me/${node.node_source_id}`;
    case 'facebook':
      return `https://facebook.com/${node.username}`;
    case 'vkontakte':
      return `https://vk.com/id${node.node_source_id}`;
    default:
      return '';
  }
};

export const getPlatformName = (platform: Platform) => {
  const names: Record<Platform, string> = {
    twitter: 'X',
    facebook: 'Facebook',
    youtube: 'YouTube',
    telegram: 'Telegram',
    vkontakte: 'VKontakte',
  };
  return names[platform];
};
