import {} from 'react';
import { UnorderedList, ListItem } from '@chakra-ui/react';
import { BodyText, Box, Flex, TitleHeader } from '~/components';
import { colors } from '~/styles';

export function WhatsNew() {
  return (
    <Box p={4} bg="#2c264a" color={colors.white} my={4} borderRadius={8}>
      <TitleHeader mb={2}>{`What's New in the Graphika Platform:`}</TitleHeader>
      <UnorderedList>
        <ListItem ml={1}>
          May 8: You can now access Graphika data in the platform through charts
          in insights. Interactive charts, including activity charts and map
          highlights allow you to explore the data driving an insight.
        </ListItem>
      </UnorderedList>
    </Box>
  );
}
