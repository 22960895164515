import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  theme,
  BoxProps,
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { ContentRenderer } from '~/components';
import { colorsV2 as colors } from '~/styles/v2/colorsV2';
import { Body } from './TypographyV2';

const popoverContentStyle: BoxProps = {
  bg: colors.white,
  border: `1px solid ${colors.outline}`,
  borderRadius: 8,
  px: 1,
  py: 1,
  width: 'fit-content',
  zIndex: theme.zIndices.popover,
};

type Props = PopoverProps &
  PropsWithChildren<{
    component?: JSX.Element;
    placement?: PopoverProps['placement'];
    text?: string;
    trigger?: PopoverProps['trigger'];
    maxW?: string;
    display?: 'block' | 'inline-block';
  }>;

export function Tooltip({
  children,
  component,
  placement = 'top',
  text,
  trigger = 'hover',
  maxW = '300px',
  display = 'inline-block',
  ...rest
}: Props) {
  return (
    <Popover
      placement={placement}
      trigger={trigger}
      offset={[0, 1]}
      {...(rest as any)}
    >
      <PopoverTrigger>
        <div style={{ display, cursor: 'pointer' }}>
          {typeof children === 'string' ? <Body>{children}</Body> : children}
        </div>
      </PopoverTrigger>
      <PopoverContent {...({ ...popoverContentStyle, maxW } as any)}>
        <PopoverBody px={1} py={1}>
          <ContentRenderer
            color={colors.secondaryText}
            sx={{
              a: {
                color: colors.primary.main,
              },
            }}
            fontSize="12px"
          >
            {text ?? ''}
          </ContentRenderer>

          {!!component && component}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
