import type { ReactElement } from 'react';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { useIsFetching } from '@tanstack/react-query';
import { Box, ButtonGroup, Fade, Flex, keyframes } from '@chakra-ui/react';
import {
  Button,
  Icon,
  Link,
  Tooltip,
  PageHeaderAccountMenu,
} from '~/components';
import { colors, boxShadows } from '~/styles';
import {
  authoringRouteRegex,
  feedsRouteRegex,
  mapviewerRouteRegex,
  reportsRouteRegex,
  useDefaultRouteForUser,
} from '~/lib/routing';
import { useUser } from '~/lib/hooks/useUser';
import GLogo from '~/public/G_Logox4.png';
import FileLines from '~/public/icons/FileLines.svg';
import Pencil from '~/public/icons/Pencil.svg';
import Globe from '~/public/icons/Globe.svg';
import ReportIcon from '~/public/icons/Report.svg';
import QuestionIcon from '~/public/icons/QuestionMark.svg';

const bg = keyframes`
from {
  transform: translateX(0)
} to {
  transform: translateX(32px)
}`;

export function PageHeaderBar({}: {}): ReactElement {
  const { pathname } = useRouter();
  const homePath = useDefaultRouteForUser({ asObject: false }) as string;
  const isFetching = useIsFetching();
  const { userInfo } = useUser();
  const isNavigationDisabled = userInfo?.password_reset_required;

  return (
    <Box
      bg={colors.g.primary}
      px={7}
      boxShadow={boxShadows.header}
      zIndex={20}
      pointerEvents={isNavigationDisabled ? 'none' : 'auto'}
    >
      <Box position="fixed" left={0} right={0} top="44px">
        <Fade
          in={isFetching !== 0}
          transition={{
            enter: {
              delay: 0.1,
              duration: 0.2,
            },
            exit: {
              delay: 0.1,
              duration: 0.2,
            },
          }}
        >
          <Box
            background={`repeating-linear-gradient(
            145deg,
            ${colors.g.light} 1px,
            ${colors.g.pale} 2px,
            ${colors.g.pale} 11px,
            ${colors.g.light} 12px,
            ${colors.g.light} 20px
          );`}
            w={`calc(100vw + 20px)`}
            animation={`${bg} infinite 1s linear`}
            h="2px"
          />
        </Fade>
      </Box>
      <Flex justify="space-between" alignItems="center" h="46px">
        <Flex align="center" position="relative">
          <Link as={Box} href={homePath} pt="5px" pr="14px">
            <NextImage
              src={GLogo}
              alt="Graphika Logo"
              width="18"
              height="21"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Link>
        </Flex>
        {userInfo?.role === 'intelligence reader' ||
        userInfo?.role === 'discovery viewer' ? (
          <ReaderButtonGroup pathname={pathname} />
        ) : userInfo?.role === 'admin' || userInfo?.role === 'analyst' ? (
          <AdminAnalystButtonGroup pathname={pathname} />
        ) : (
          <></>
        )}
        <Flex gap={2} align="center">
          <Link href="/docs" target="_blank" mr={-2}>
            <Icon icon={QuestionIcon} fill={colors.white} />
          </Link>
          {(userInfo?.role === 'admin' || userInfo?.role === 'analyst') && (
            <PageHeaderReportLink isActive={reportsRouteRegex.test(pathname)} />
          )}
          <PageHeaderAccountMenu />
        </Flex>
      </Flex>
    </Box>
  );
}

export function PageHeaderBarGroupButton({
  href,
  icon,
  isActive,
  label,
}: {
  href: string;
  icon: () => ReactElement;
  isActive: boolean;
  label: string;
}): ReactElement {
  const router = useRouter();
  return (
    <Tooltip label={label} placement="bottom" mt="6px" closeOnClick={false}>
      {/*// @ts-ignore */}
      <Link
        as={Button}
        tabIndex={-1}
        border={0}
        icon={icon}
        pl={4}
        pr={4}
        pt={1}
        pb={1}
        background={isActive ? colors.g.dark : colors.white}
        color={isActive ? colors.white : 'currentColor'}
        onClick={() => router.push(href)}
        _hover={{
          background: isActive ? colors.g.plum : colors.g.lavender,
          color: isActive ? colors.g.lavender : colors.g.plum,
        }}
        _focus={{
          background: isActive ? colors.g.plum : colors.g.lavender,
        }}
      />
    </Tooltip>
  );
}

type ButtonGroupProps = {
  pathname: string;
};

const ReaderButtonGroup = ({ pathname }: ButtonGroupProps) => {
  return (
    <ButtonGroup color={colors.g.primary} isAttached spacing="0">
      <PageHeaderBarGroupButton
        href={`/feeds`}
        icon={FileLines}
        isActive={feedsRouteRegex.test(pathname)}
        label="Intelligence Feed"
      />
      <PageHeaderBarGroupButton
        href={`/maps`}
        icon={Globe}
        isActive={mapviewerRouteRegex.test(pathname)}
        label="Map Viewer"
      />
    </ButtonGroup>
  );
};

const AdminAnalystButtonGroup = ({ pathname }: ButtonGroupProps) => {
  const {
    query: { feedId },
  } = useRouter();
  const feedIdParam = feedId ? `?feedId=${feedId}` : '';
  return (
    <ButtonGroup color={colors.g.primary} isAttached spacing="0">
      <PageHeaderBarGroupButton
        href={`/feeds${feedIdParam}`}
        icon={FileLines}
        isActive={feedsRouteRegex.test(pathname)}
        label="Intelligence feed"
      />
      <PageHeaderBarGroupButton
        href={`/maps`}
        icon={Globe}
        isActive={mapviewerRouteRegex.test(pathname)}
        label="Map Viewer"
      />
      <PageHeaderBarGroupButton
        href={`/authoring${feedIdParam}`}
        icon={Pencil}
        isActive={authoringRouteRegex.test(pathname)}
        label="Authoring"
      />
    </ButtonGroup>
  );
};

const PageHeaderReportLink = ({ isActive }: { isActive: boolean }) => (
  <Link href="/reports" data-cy="reports-link">
    <Box
      borderRadius="100%"
      bg={isActive ? colors.g.dark : undefined}
      h={8}
      w={8}
    >
      <Icon icon={ReportIcon} h="32px" w="32px" />
    </Box>
  </Link>
);
