import type { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { signOut as googleSignOut } from 'next-auth/react';
import {
  useColorMode,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import {
  BodyText,
  Box,
  CaptionText,
  Divider,
  Flex,
  Icon,
  Link,
  Switch,
} from '~/components';
import { colors } from '~/styles';
import { logoutAction } from '~/redux/userSlice';
import { useAppDispatch } from '~/redux/store';
import { clearLocalUserState, logout as apiLogout, roleName } from '~/lib/auth';
import PersonIcon from '~/public/icons/Profile.svg';
import DarkMode from '~/public/icons/Dark Mode.svg';
import LightMode from '~/public/icons/Light Mode.svg';
import ExternalLink from '~/public/icons/ExternalLink.svg';
import Cog from '~/public/icons/Cog.svg';
import PowerOff from '~/public/icons/poweroff.svg';
import { useUser } from '~/lib/hooks/useUser';

const blackWithOpacity32 = '#ADADAD';

export function PageHeaderAccountMenu(): ReactElement {
  const { toggleColorMode, colorMode } = useColorMode();
  const { userInfo } = useUser();
  const dispatch = useAppDispatch();
  const router = useRouter();

  const logout = async () => {
    try {
      await apiLogout();
      await googleSignOut({ redirect: false });
    } catch (e) {
      console.error('error logging out', e);
      dispatch(logoutAction());
      router.push('/login');
    } finally {
      clearLocalUserState();
      dispatch(logoutAction());
      router.push('/login');
    }
  };

  const buttonBg = useColorModeValue(colors.white, colors.coolGray[2]);
  const primary = useColorModeValue(colors.g.primary, colors.g.light);
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[1]);

  return (
    <Menu>
      <MenuButton as={Box} cursor="pointer">
        <Icon
          cursor="pointer"
          icon={PersonIcon}
          fill={colors.white}
          h="24px"
          w="24px"
        />
      </MenuButton>
      <MenuList w="272px">
        <Box px={4}>
          <BodyText>{userInfo?.username}</BodyText>
          <BodyText color={gray1}>
            {roleName[userInfo?.role ?? 'intelligence reader']}
          </BodyText>
        </Box>
        <Divider />
        <Flex align="center" justify="space-between" w="100%" gap={2} px={4}>
          <BodyText>Appearance</BodyText>
          <Flex gap={2}>
            <Icon
              icon={LightMode}
              color={
                colorMode === 'light' ? colors.g.primary : blackWithOpacity32
              }
              boxSize={6}
              cursor="pointer"
              onClick={toggleColorMode}
            />
            <Switch
              variant="monochrome"
              onChange={toggleColorMode}
              isChecked={colorMode === 'dark'}
            />
            <Icon
              icon={DarkMode}
              color={
                colorMode === 'dark' ? colors.g.primary : blackWithOpacity32
              }
              boxSize={6}
              cursor="pointer"
              onClick={toggleColorMode}
            />
          </Flex>
        </Flex>
        <Divider />
        <Flex align="center" px={4}>
          <Icon icon={Cog} fill={gray1} boxSize={6} />
          <Link href={userInfo?.role === 'admin' ? '/admin' : '/settings'}>
            <BodyText mt={-2}>
              {userInfo?.role === 'admin'
                ? 'Admin settings'
                : 'Account settings'}
            </BodyText>
          </Link>
        </Flex>
        <Divider mt={2} />
        <Flex align="center" px={4}>
          <Icon icon={PowerOff} fill={gray1} boxSize={4} mr={2} />
          <Link onClick={logout}>
            <BodyText>Sign out</BodyText>
          </Link>
        </Flex>
        <Divider mb={0} />

        <Flex
          bg={gray6}
          mb={-4}
          direction="column"
          p={4}
          gap={4}
          borderBottomRightRadius={16}
          borderBottomLeftRadius={16}
        >
          <Link href="https://graphika.com/privacy-policy">
            <CaptionText textAlign="center" color={gray1}>
              Privacy Policy
            </CaptionText>
          </Link>
        </Flex>
      </MenuList>
    </Menu>
  );
}
